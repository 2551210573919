interface Publication {
  title: string;
  authors: Array<string>;
  journal: string;
  url?: string;
  details: Array<string>;
}

const PUBLICATIONS: Array<Publication> = [
  {
    title: 'Somatic variant calling from single-cell DNA sequencing data',
    journal: 'Computational and Structural Biotechnology Journal',
    authors: ['Monica Valecha', 'David Posada'],
    url: 'https://doi.org/10.1016/j.csbj.2022.06.013',
    details: ['Volume 20', '2022', 'Pages 2978-2985', 'ISSN2001-0370'],
  },
  {
    title:
      'Clonality and timing of relapsing colorectal cancer metastasis revealed through whole-genome single-cell sequencing',
    journal: 'Cancer Letters',
    authors: [
      'Joao M. Alves',
      'Sonia Prado-López',
      'Laura Tomás',
      'Monica Valecha',
      'Nuria Estévez-Gómez',
      'Pilar Alvariño',
      'Dominik Geisel',
      'Dominik Paul Modest',
      'Igor M. Sauer',
      'Johann Pratschke',
      'Nathanael Raschzok',
      'Christine Sers',
      'Soulafa Mamlouk',
      'David Posada',
    ],
    url: 'https://doi.org/10.1016/j.canlet.2022.215767',
    details: ['Volume 543', '2022', '215767', 'ISSN 0304-3835'],
  },
  {
    title:
      'NOTCH1 activation compensates BRCA1 deficiency and promotes triple-negative breast cancer formation',
    journal: 'Nat Commun 11',
    authors: ['Miao, K.', 'Lei', 'J.H.', 'Valecha, M.V', 'et al.'],
    url: 'https://doi.org/10.1038/s41467-020-16936-9',
    details: ['3256', '(2020)'],
  },
  {
    title:
      'BRCA1 represses DNA replication initiation through antagonizing estrogen signaling and maintains genome stability in parallel with WEE1-MCM2 signaling during pregnancy',
    journal: 'Human Molecular Genetics',
    authors: [
      'Xiaoling Xu',
      'Eric Chen',
      'Lihua Mo',
      'Lei Zhang',
      'Fangyuan Shao',
      'Kai Miao',
      'Jianlin Liu',
      'Sek Man Su',
      'Monica Valecha',
      'Un In Chan',
      'Hongping Zheng',
      'Mark Chen',
      'Weiping Chen',
      'Qiang Chen',
      'Haiqing Fu',
      'Mirit I Aladjem',
      'Yanzhen He',
      'Chu-Xia Deng',
    ],
    url: 'https://doi.org/10.1093/hmg/ddy398',
    details: ['Volume 28', 'Issue 5', '1 March 2019', 'Pages 842-857'],
  },
  {
    title:
      'Molecular landscape and subtype-specific therapeutic response of nasopharyngeal carcinoma revealed by integrative pharmacogenomics',
    journal: 'Nat Commun 12',
    authors: [
      'Ren-Bo Ding',
      'Ping Chen',
      'Barani Kumar Rajendran',
      'Xueying Lyu',
      'Haitao Wang',
      'Jiaolin Bao',
      'Jianming Zeng',
      'Wenhui Hao',
      'Heng Sun',
      'Ada Hang-Heng Wong',
      'Monica Vishnu Valecha',
      'Eun Ju Yang',
      'Sek Man Su',
      'Tak Kan Choi',
      'Shuiming Liu',
      'Kin Iong Chan',
      'Ling-Lin Yang',
      'Jingbo Wu',
      'Kai Miao',
      'Qiang Chen',
      'Joong Sup Shim',
      'Xiaoling Xu',
      'Chu-Xia Deng.',
    ],
    url: 'https://doi.org/10.1038/s41467-021-23379-3',
    details: ['3046', '(2021)'],
  },
  {
    title: 'Single-cell mtDNA heteroplasmy in colorectal cancer',
    journal: 'Genomics 114: 110315',
    authors: [
      'Almeida J',
      'Pérez-Figueroa AP',
      'Alves JM',
      'Valecha M',
      'Prado-López A',
      'Alvarinõ P',
      'Cameselle-Teijeiro JM',
      'Chantada D',
      'Fonseca MM',
      'Posada D.',
    ],
    details: ['IF (2020): 5.736. Q1', 'INT'],
  },
  {
    title:
      'Towards scalable phylogeny-aware inference of single-nucleotide variations from single-cell DNA sequencing data',
    journal: 'Bioinformatics, in press',
    authors: [
      'Edrisi M',
      'Valecha M',
      'Chowdary SBV',
      'Robledo S',
      'Ogilvie HA',
      'Posada D',
      'Zafar H',
      'Nakhleh L. Phylovar',
    ],
    details: ['IF: 6.937', 'D1', 'INT'],
  },
  {
    title:
      'SIEVE: joint inference of single-nucleotide variants and cell phylogeny from single- cell DNA sequencing data',
    journal: 'bioRxiv',
    authors: [
      'Senbai Kang',
      'Nico Borgsmüller',
      'Monica Valecha',
      'Jack Kuipers',
      'Joao Alves',
      'Sonia Prado-López',
      'Débora Chantada',
      'Niko Beerenwinkel',
      'David Posada',
      'Ewa Szczurek',
    ],
    url: 'https://doi.org/10.1101/2022.03.24.485657',
    details: ['bioRxiv', '2022.03.24.485657;', 'doi:'],
  },
  {
    title:
      'Comparative analysis of capture methods for genomic profiling of circulating tumor cells in colorectal cancer',
    journal: 'medRxiv',
    authors: [
      'Joao M. Alves',
      'Nuria Estévez-Gómez',
      'Monica Valecha',
      'Sonia Prado-López',
      'Laura Tomás',
      'Pilar Alvariño',
      'Roberto Piñeiro',
      'Laura Muinelo-Romay',
      'Patricia Mondelo-Macía',
      'Mercedes Salgado',
      'Agueda Iglesias-Gómez',
      'Laura Codesido-Prada',
      'Joaquin Cubiella',
      'David Posada.',
    ],
    url: 'https://doi.org/10.1101/2022.06.02.22275905',
    details: ['medRxi', '2022.06.02.22275905', 'doi'],
  },
  {
    title: `Apert's syndrome: Study by whole exome sequencing`,
    journal: 'Genes & Diseases',
    authors: [
      'Anjana Munshi',
      'Preeti Khetarpal',
      'Satrupa Das',
      'Venkateshwar Rao',
      'Monica Valecha',
      'Manita Bansal',
      'Roshan Kumar',
    ],
    url: 'https://doi.org/10.1016/j.gendis.2017.07.008',
    details: ['Volume 5', 'Issue 2', '2018', 'Pages 119-122', 'ISSN 2352-3042'],
  },
];

export { PUBLICATIONS, type Publication };
