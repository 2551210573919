import { makeStyles } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { SocialLinkStyles } from './SocialLink.types';

const useSocialLinkStyles = makeStyles<keyof SocialLinkStyles>({
  root: {
    ...shorthands.borderRadius('8px'),
    ...shorthands.padding('16px'),
    ...shorthands.gap('8px'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    width: '100%',
    textAlign: 'center',
  },
  logo: {
    justifySelf: 'flex-start',
    width: '24px',
    height: '24px',

    '> path': {
      fill: MonicaValechaTokens.textColor,
    },
  },
});

export { useSocialLinkStyles };
