import { Footer, BackToTop } from './components';
import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react';

const Layout: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Fragment>
    {children}
    <BackToTop />
    <Footer />
  </Fragment>
);

export { Layout };
