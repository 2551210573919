import { makeStyles } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { DropDownStyles } from './DropDown.types';

const useDropDownStyles = makeStyles<keyof DropDownStyles>({
  root: {},
  item: {
    ...shorthands.padding('0.5rem', '0.75rem'),
    ...shorthands.borderTop('2px', 'solid', 'transparent'),
    ...shorthands.borderBottom('2px', 'solid', 'transparent'),
    ...shorthands.transition([
      ['color', '0.2s', 'ease-out'],
      ['border-bottom-colo', '0.2s', 'ease-out'],
    ]),
    ...shorthands.overflow('hidden'),
    ...shorthands.margin('0'),
    boxSizing: 'border-box',
    userSelect: 'none',
    color: MonicaValechaTokens.navBarColor,
    letterSpacing: '0.01em',
    backgroundColor: 'transparent',
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    flexGrow: '0',
    flexShrink: '0',
    lineHeight: '24px',
    fontSize: '18px',
    fontWeight: '400',

    '&:hover': {
      color: MonicaValechaTokens.blueHighlight,
      fontWeight: '700',
      backgroundColor: 'transparent',
    },

    '@media all and (max-width: 599px)': {
      ...shorthands.padding('0.75rem', '0.2rem', '0.75rem', '0.2rem'),
      fontSize: '0.95rem',
      borderTopWidth: '4px',
    },
  },
  innerItem: {
    ...shorthands.padding('0'),
  },
  isActive: {
    color: MonicaValechaTokens.blueHighlight,
    borderBottomColor: MonicaValechaTokens.blueHighlight,
    fontWeight: '700',
    ...shorthands.overflow('hidden'),

    '@media all and (max-width: 599px)': {
      borderBottomColor: MonicaValechaTokens.blueHighlight,
    },
  },
  link: {
    color: MonicaValechaTokens.navBarColor,
    ...shorthands.overflow('hidden'),

    '&:hover': {
      color: MonicaValechaTokens.blueHighlight,
      borderBottomColor: MonicaValechaTokens.blueHighlight,

      '@media all and (max-width: 599px)': {
        borderBottomColor: MonicaValechaTokens.blueHighlight,
      },
    },
  },
  noLink: {
    color: MonicaValechaTokens.navBarColor,
    ...shorthands.overflow('hidden'),
  },
  menu: {
    backgroundColor: MonicaValechaTokens.navBarBGColor,
    ...shorthands.overflow('hidden'),
  },
  menuList: {},
});

export { useDropDownStyles };
