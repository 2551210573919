import { Hero, MetaInfo, Navbar } from '../../components';
import { useState, type FunctionComponent } from 'react';
import { getRouteMetaInfo } from '../../config/routes.config';
import { useExperienceStyles } from './Experience.styles';
import { EXPERIENCE, type JobInfo } from '../../config/experience';
import { JobCard } from '../../components/JobCard';

const Experience: FunctionComponent = () => {
  const classes = useExperienceStyles();
  const [heroVissible, setHeroVisibility] = useState<boolean>(true);

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('EXPERIENCE')} />
      <Hero onVisibilityChange={(isVissible: boolean) => setHeroVisibility(isVissible)} />
      <Navbar showLogo={!heroVissible} />
      <section className={classes.content}>
        <div className={classes.contentWrapper}>
          {EXPERIENCE.map((job: JobInfo) => {
            return <JobCard job={job} key={job.company} />;
          })}
        </div>
      </section>
    </main>
  );
};

export { Experience };
