import { makeStyles, shorthands } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import type { ExperienceStyles } from './Experience.types';

const useExperienceStyles = makeStyles<keyof ExperienceStyles>({
  root: {
    boxSizing: 'border-box',
    minHeight: '2000px',
    width: '100%',
    height: '100%',
  },
  content: {
    ...shorthands.padding('16px'),
    ...shorthands.gap('24px'),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: '100%',
  },
  contentWrapper: {
    ...shorthands.gap('16px'),
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '1fr',
    alignItems: 'start',
    justifyContent: 'center',
    maxWidth: MonicaValechaTokens.maxContentWidth,

    '@media all and (min-width: 1200px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    '@media all and (min-width: 900px) and (max-width: 1299px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    '@media all and (min-width: 600px) and (max-width: 899px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },

    '@media all and (min-width: 300px) and (max-width: 599px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  job: {
    ...shorthands.borderRadius('8px'),
    ...shorthands.padding('16px'),
    backgroundColor: MonicaValechaTokens.jobCardBackground,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
});

export { useExperienceStyles };
