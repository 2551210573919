import type { FunctionComponent } from 'react';
import { routes, type Route as RouteDef } from './config/routes.config';
import { MetaInfo } from './components';
import { useLocation, Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { useScrollToTop } from './hooks';
import { NotFound404Error } from './containers';
import { usePageTracker } from './hooks/usePageTracker';

const getAppRoutes = (props: { routes: Array<RouteDef> }) => {
  const routeDefinitions = props.routes.map(({ id, path, Component, children }) => {
    const rootRoute = <Route key={id} path={path} element={<Component />} />;

    if (children && children.length > 0) {
      let childrenRoutes = new Array(0);
      childrenRoutes = getAppRoutes({ routes: children });
      return [rootRoute, ...childrenRoutes];
    } else {
      return rootRoute;
    }
  });

  return routeDefinitions;
};

const App: FunctionComponent = () => {
  useScrollToTop();
  usePageTracker(); // Un-Comment to enable Google Analytics
  const location = useLocation();

  return (
    <Layout>
      <MetaInfo />
      <Routes location={location}>
        {getAppRoutes({ routes: routes })}
        <Route path="*" element={<NotFound404Error />} />
      </Routes>
    </Layout>
  );
};

export { App };
