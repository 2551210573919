import {
  bionividUrl,
  bionividWebPUrl,
  positiveBioscienceUrl,
  positiveBioscienceWebPUrl,
  ulUrl,
  ulWebPUrl,
  universityOfMacauUrl,
  universityOfMacauWebPUrl,
  uvigoUrl,
  uvigoWebPUrl,
} from '../assets/img/logos';

enum JobType {
  Acadademia = 'Academia',
  Industry = 'Industry',
}

interface JobInfo {
  type: JobType;
  company: string;
  companyShort?: string;
  supervisor?: string;
  startDate: Date;
  endDate: Date | null;
  titles: Array<string>;
  highlights: Array<string>;
  city: string;
  country: string;
  isRemote?: boolean;
  url?: string;
  logo?: string;
  logoWebP?: string;
  skills: Array<string>;
}

const EXPERIENCE: Array<JobInfo> = [
  {
    type: JobType.Acadademia,
    company: 'University of Limerick',
    companyShort: 'UL',
    supervisor: 'Aedin Culhane',
    startDate: new Date('01 February 2023'),
    endDate: null,
    titles: ['Research Assistant'],
    highlights: [
      'Cloud computing projects of developing bioinformatics pipelines on terra using Google cloud and Microsoft Azure.',
      'Learning Hi-C Sequencing Analysis in R, along with using Bioconductor and Jupyter notebook in terra.',
    ],
    city: 'Dublin',
    country: 'Ireland',
    isRemote: true,
    url: 'https://www.ul.ie/',
    logo: ulUrl,
    logoWebP: ulWebPUrl,
    skills: ['R-Bioconductor', 'Azure', 'Python', 'Cloud Services', 'AnVIL'],
  },
  {
    type: JobType.Acadademia,
    company: 'Universidad de Vigo',
    companyShort: 'UVigo',
    supervisor: 'David Posada',
    startDate: new Date('01 July 2019'),
    endDate: null,
    titles: ['PhD Student', 'Early Stage Researcher'],
    highlights: [
      'Developed and implemented single cell NGS data analysis pipelines with a focus on variant calling for cancer research.',
      'Analyzed large-scale public and in-house datasets consisting of thousands of whole genome sequenced cells using slurm and HPC clusters.',
      'Simulated single cell NGS data for benchmarking purposes and evaluated different tools and methods.',
      'Investigated mutational signatures in cancer data using both bulk and single cell datasets and published findings in peer-reviewed journals.',
    ],
    city: 'Vigo',
    country: 'Spain',
    isRemote: false,
    url: 'https://www.uvigo.gal/',
    logo: uvigoUrl,
    logoWebP: uvigoWebPUrl,
    skills: [
      'Slurm',
      'R',
      'Mutational Signatures',
      'Single Cell',
      'Cloud Services',
      'Variant Calling',
      'NGS Data Analysis',
      'ITN-CONTRA',
      'Marie Skłodowska-Curie Fellow',
    ],
  },
  {
    type: JobType.Acadademia,
    company: 'University of Macau',
    supervisor: 'Chuxia Deng',
    startDate: new Date('01 June 2016'),
    endDate: new Date('01 June 2018'),
    titles: ['Research Assistant'],
    highlights: [
      'Developed and Standarized pipelines for cancer patients with focus on breast cancer.',
      'Worked in colaboration with Hospitals and Oncologists that provided us with patient samples.',
      'Developed transposon analysis pipeline for mouse models.',
      'Co-authored / Collaborated with major ioinformatics work in different publications as a research assistant.',
    ],
    city: 'Macao',
    country: 'China',
    isRemote: false,
    url: 'https://www.um.edu.mo/',
    logo: universityOfMacauUrl,
    logoWebP: universityOfMacauWebPUrl,
    skills: [
      'HPC',
      'Mouse models',
      'Transposon sequencing',
      'TCGA',
      'BRCA analysis',
      'R',
    ],
  },
  {
    type: JobType.Industry,
    company: 'Positive Bioscience',
    startDate: new Date('01 November 2014'),
    endDate: new Date('01 May 2016'),
    titles: ['Scientific Officer'],
    highlights: [
      'Analyzed patients sequencing data and provided them with drug treatment options.',
      'Developed RNA-Seq variant calling and expression analysis pipeline.',
      'Worked with SNP array data analysis.',
      'Database developement with personalized treatment options based on mutations and gene expression.',
    ],
    city: 'Mumbai',
    country: 'India',
    isRemote: false,
    url: 'https://positivebioscience.com/',
    logo: positiveBioscienceUrl,
    logoWebP: positiveBioscienceWebPUrl,
    skills: [
      'RNA-Seq Analysis',
      'Whole genome and exome Sequencing',
      'Precision medicine',
      'Cancer genomics',
    ],
  },
  {
    type: JobType.Industry,
    company: 'Bionivid Technology',
    startDate: new Date('01 July 2013'),
    endDate: new Date('01 October 2014'),
    titles: ['Genome Artist'],
    highlights: [
      'Trained wet lab scientists and professionals in bioinformatics analysis.',
      'Focused on various next generation sequence analysis projects for different organisms and laboratories from researchers in India.',
      'Performed NGS Analysis along with Project Designs and their applications in close association with scientists and researchers.',
      'Performed DNA-Seq, RNA-Seq, Chip-Seq and small-rna analysis in viruses,bacteria,plants and humans.',
      'Handled many samples and different projects at the same time.',
    ],
    city: 'Bangalore',
    country: 'India',
    isRemote: false,
    url: 'https://www.bionivid.com/',
    logo: bionividUrl,
    logoWebP: bionividWebPUrl,
    skills: [
      'SNV Analysis',
      'mRNA analysis',
      'WGS/WXS/microarray',
      'Genome Assembly',
      'Shell scripting',
      'Microsoft Excel',
      'NGS Training',
    ],
  },
];

export { EXPERIENCE, type JobInfo, JobType };
