import { makeStyles } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { DegreeCardStyles } from './DegreeCard.types';

const useDegreeCardStyles = makeStyles<keyof DegreeCardStyles>({
  root: {
    ...shorthands.borderRadius('8px'),
    ...shorthands.padding('16px'),
    ...shorthands.gap('8px'),
    backgroundColor: MonicaValechaTokens.jobCardBackground,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  degree: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    width: '100%',
    textAlign: 'center',
  },
  institution: {
    ...shorthands.gap('4px'),
    ...shorthands.padding('0'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '13px',
    fontWeight: '200',
    lineHeight: '16px',
    color: 'rgb(87, 87, 87)',
    textAlign: 'center',
    width: '100%',
  },
  date: {
    ...shorthands.borderTop('1px', 'solid', 'rgb(87, 87, 87)'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '12px',
    fontWeight: '800',
    lineHeight: '13px',
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '13px',
    width: '100%',
  },
  logo: {
    justifySelf: 'flex-start',
    width: '24px',
    height: '24px',
  },
});

export { useDegreeCardStyles };
