import {
  EducationCard,
  ExperienceCard,
  Hero,
  MetaInfo,
  Navbar,
  SkillsCloud,
  SocialLinks,
} from '../../components';
import { useState, type FunctionComponent } from 'react';
import { getRouteMetaInfo } from '../../config/routes.config';
import { useHomeStyles } from './Home.styles';
import { PERSONAL_INFO } from '../../config/about';
import { EXPERIENCE } from '../../config/experience';

const Home: FunctionComponent = () => {
  const classes = useHomeStyles();
  const [heroVissible, setHeroVisibility] = useState<boolean>(true);

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('HOME')} />
      <Hero onVisibilityChange={(isVissible: boolean) => setHeroVisibility(isVissible)} />
      <Navbar showLogo={!heroVissible} />
      <section className={classes.content}>
        <SocialLinks
          cv={PERSONAL_INFO.cv}
          email={PERSONAL_INFO.email}
          github={PERSONAL_INFO.github}
          linkedin={PERSONAL_INFO.linkedin}
          scholar={PERSONAL_INFO.scholar}
          twitter={PERSONAL_INFO.twitter}
          compact
        />
        <ExperienceCard experience={EXPERIENCE} compact={true} />
        <EducationCard education={PERSONAL_INFO.education} />
        <SkillsCloud skills={PERSONAL_INFO.skills} />
      </section>
    </main>
  );
};

export { Home };
