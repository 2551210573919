import { Hero, MetaInfo, Navbar, PublicationCard } from '../../components';
import { useState, type FunctionComponent } from 'react';
import { getRouteMetaInfo } from '../../config/routes.config';
import { usePublicationsStyles } from './Publications.styles';
import { PUBLICATIONS, type Publication } from '../../config/publications';

const Publications: FunctionComponent = () => {
  const classes = usePublicationsStyles();
  const [heroVissible, setHeroVisibility] = useState<boolean>(true);

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('PUBLICATIONS')} />
      <Hero onVisibilityChange={(isVissible: boolean) => setHeroVisibility(isVissible)} />
      <Navbar showLogo={!heroVissible} />
      <section className={classes.content}>
        <div className={classes.contentWrapper}>
          {PUBLICATIONS.map((publication: Publication) => {
            return <PublicationCard publication={publication} key={publication.title} />;
          })}
        </div>
      </section>
    </main>
  );
};

export { Publications };
