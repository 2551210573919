import { useMemo, type FunctionComponent } from 'react';
import { routes as ROUTES, type Route } from '../../config/routes.config';
import { useNavBarStyles } from './NavBar.styles';
import type { NavBarProps } from './NavBar.types';
import { DropDown } from '../DropDown';
import { NavLink } from 'react-router-dom';
import { Image } from '@fluentui/react-components';
import { appLogoOutUrl, appLogoOutWebPUrl } from '../../assets/img';

const Navbar: FunctionComponent<NavBarProps> = (props) => {
  const classes = useNavBarStyles();
  const routes = useMemo(() => ROUTES, []);

  return (
    <nav role="navigation" aria-label="Main navigation" className={classes.root}>
      <div className={classes.wrapper}>
        {props.showLogo ? (
          <div className={classes.brandWrapper}>
            <NavLink
              end
              to={'/'}
              style={{ display: 'flex', alignItems: 'flex-end', alignSelf: 'flex-end' }}>
              <picture>
                <source srcSet={appLogoOutWebPUrl} type="image/webp" />
                <source srcSet={appLogoOutUrl} type="image/png" />
                <Image
                  role="img"
                  src={appLogoOutUrl}
                  alt={`Logo for Monica Valecha's personal website`}
                  width={'124'}
                />
              </picture>
            </NavLink>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.routes}>
          {routes.map((route: Route) => (
            <DropDown key={route.id} route={route} />
          ))}
        </div>
      </div>
    </nav>
  );
};

export { Navbar };
