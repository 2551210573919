enum SocialLinkType {
  Email = 'email',
  Twitter = 'twitter',
  Scholar = 'scholar',
  Github = 'github',
  Linkedin = 'linkedin',
  CV = 'cv',
}

interface SocialLinkProps {
  type: SocialLinkType;
  value?: string;
}

interface SocialLinkStyles {
  root: string;
  title: string;
  logo: string;
}

export { type SocialLinkProps, type SocialLinkStyles, SocialLinkType };
