import { useMemo, type FunctionComponent } from 'react';
import { useJobCardStyles } from './JobCard.styles';
import type { JobCardProps } from './JobCard.types';
import { Text, Image, Link, mergeClasses } from '@fluentui/react-components';

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
};

const JobCard: FunctionComponent<JobCardProps> = (props) => {
  const classes = useJobCardStyles();

  const job = useMemo(() => props.job, [props.job]);

  const logo = useMemo(() => {
    if (job.logo || job.logoWebP) {
      return (
        <picture>
          {job.logoWebP ? <source srcSet={job.logoWebP} type="image/webp" /> : <></>}
          {job.logo ? <source srcSet={job.logo} type="image/png" /> : <></>}
          <Image
            className={classes.logo}
            alt={job.company}
            src={job.logo ?? job.logoWebP}
            width={props.compact ? '56px' : '84px'}
            height={props.compact ? '56px' : '84px'}
          />
        </picture>
      );
    } else {
      return <></>;
    }
  }, [job.logo, job.logoWebP]);

  const displayTime = useMemo(() => {
    const start = `${job.startDate.toLocaleDateString('en-US', DATE_FORMAT)}`;
    let end = `Current`;
    if (job.endDate) {
      end = `${job.endDate.toLocaleDateString('en-US', DATE_FORMAT)}`;
    }

    return `${start} - ${end}`;
  }, [job.startDate, job.endDate]);

  if (props.compact) {
    return (
      <article
        key={job.company}
        className={mergeClasses(
          classes.root,
          props.compact ? classes.smallGap : undefined
        )}>
        {logo}
        {job.url ? (
          <Link href={job.url} aria-label={job.company} title={job.company}>
            <Text as={'h3'} className={classes.title}>
              {job.company}
            </Text>
          </Link>
        ) : (
          <Text as={'h3'} className={classes.title}>
            {job.company}
          </Text>
        )}
        <Text as={'h4'} className={classes.position}>
          {job.titles.join(' | ')}
        </Text>
        <Text as={'p'} className={classes.date}>
          {displayTime}
        </Text>
        <Text
          as={'p'}
          className={props.compact ? classes.compactLocation : classes.location}>
          {`${job.city}, ${job.country}`}
        </Text>
      </article>
    );
  } else {
    return (
      <article key={job.company} className={classes.root}>
        {logo}
        {job.url ? (
          <Link href={job.url} aria-label={job.company} title={job.company}>
            <Text as={'h2'} className={classes.title}>
              {job.company}
            </Text>
          </Link>
        ) : (
          <Text as={'h2'} className={classes.title}>
            {job.company}
          </Text>
        )}
        <Text as={'h3'} className={classes.position}>
          {/* {job.titles.map((title: string) => {
          return <span key={title}>{title}</span>;
        })} */}
          {job.titles.join(' | ')}
        </Text>
        {/* <Text as={'h3'} className={classes.date}>
        {displayTime}
      </Text> */}
        <Text as={'p'} className={classes.location}>
          <span>{displayTime}</span>
          <span>{`${job.city}, ${job.country}`}</span>
        </Text>
        <ul className={classes.highlights}>
          {job.highlights.map((highlight: string, index: number) => {
            return (
              <li className={classes.highlight} key={`${job.company}_HIGHLIGHT_${index}`}>
                {highlight}
              </li>
            );
          })}
        </ul>
        {job.skills.length > 0 ? (
          <ul className={classes.tags}>
            {job.skills.map((highlight: string, index: number) => {
              return (
                <li className={classes.tag} key={`${job.company}_HIGHLIGHT_${index}`}>
                  {highlight}
                </li>
              );
            })}
          </ul>
        ) : (
          <></>
        )}
      </article>
    );
  }
};

export { JobCard };
