import { useCallback, useMemo, type FunctionComponent } from 'react';
import { useSkillsCloudStyles } from './SkillsCloud.styles';
import type { SkillsCloudProps } from './SkillsCloud.types';
import { Text } from '@fluentui/react-components';
import type { SkillInfo } from '../../config/about';
import { TagCloud, type ColorOptions } from 'react-tagcloud';

const SkillsCloud: FunctionComponent<SkillsCloudProps> = (props) => {
  const classes = useSkillsCloudStyles();

  const skills = useMemo(() => {
    return props.skills.map((skill: SkillInfo) => {
      return {
        value: skill.name,
        count: skill.level,
      };
    });
  }, [props.skills]);

  const colorOptions: ColorOptions = useMemo(() => {
    return {
      luminosity: 'dark',
      hue: 'purple',
    };
  }, []);

  const customRenderer = useCallback(
    (
      tag: {
        value: string;
        count: number;
      },
      size: number,
      color: string
    ) => (
      <h3
        key={tag.value}
        className={classes.skill}
        style={{
          fontSize: `${size}px`,
          lineHeight: `${size + 4}px`,
          color: `${color}`,
        }}>
        {tag.value}
      </h3>
    ),
    []
  );

  return (
    <article className={classes.root}>
      <Text as={'h2'} className={classes.title} id={'experience'}>
        {`Skills`}
      </Text>
      <div className={classes.cloud}>
        <TagCloud
          minSize={12}
          maxSize={35}
          tags={skills}
          shuffle={true}
          colorOptions={colorOptions}
          renderer={customRenderer}
        />
      </div>
    </article>
  );
};

export { SkillsCloud };
