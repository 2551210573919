import { makeStyles } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { PublicationCardStyles } from './PublicationCard.types';

const usePublicationCardStyles = makeStyles<keyof PublicationCardStyles>({
  root: {
    ...shorthands.borderRadius('8px'),
    ...shorthands.padding('16px'),
    ...shorthands.gap('16px'),
    backgroundColor: MonicaValechaTokens.jobCardBackground,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  smallGap: {
    ...shorthands.gap('8px'),
  },
  title: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    width: '100%',
    textAlign: 'justify',
  },
  journal: {
    ...shorthands.gap('4px'),
    ...shorthands.padding('0'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    fontWeight: '200',
    lineHeight: '16px',
    color: 'rgb(87, 87, 87)',
    textAlign: 'center',
    width: '100%',
  },
  authors: {
    ...shorthands.gap('0px'),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  separator: {
    display: 'block',
    boxSizing: 'border-box',
    paddingRight: '4px',
  },
  me: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '20px',
  },
  others: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  details: {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'start',
  },
  date: {
    ...shorthands.borderTop('1px', 'solid', 'rgb(87, 87, 87)'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '12px',
    fontWeight: '800',
    lineHeight: '13px',
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  compactLocation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '13px',
    width: '100%',
  },
  highlights: {
    ...shorthands.padding('0', '16px'),
    boxSizing: 'border-box',
    listStyleType: 'circle',
  },
  highlight: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'start',
  },
  tags: {
    ...shorthands.gap('8px'),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '16px',
  },
  tag: {
    ...shorthands.padding('8px', '16px'),
    ...shorthands.borderRadius('8px'),
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E4B3B9',
    fontWeight: '400',
  },
});

export { usePublicationCardStyles };
