import { type FunctionComponent } from 'react';
import { useEducationCardStyles } from './EducationCard.styles';
import type { EducationCardProps } from './EducationCard.types';
import { DegreeCard } from '../DegreeCard';
import { Text } from '@fluentui/react-components';

const EducationCard: FunctionComponent<EducationCardProps> = (props) => {
  const classes = useEducationCardStyles();

  return (
    <article className={classes.root}>
      <Text as={'h2'} className={classes.title} id={'education'}>
        {`Education`}
      </Text>
      <div className={classes.list}>
        {props.education.map((degree) => {
          return <DegreeCard key={degree.name} degree={degree} />;
        })}
      </div>
    </article>
  );
};

export { EducationCard };
