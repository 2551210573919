enum LanguageProficiency {
  Native = 'Native / Bilingual proficiency',
  Professional = 'Professional working proficiency',
  Limited = 'Limited working proficiency',
  Elementary = 'Elementary proficiency',
}

interface DegreeInfo {
  name: string;
  startDate: Date;
  endDate?: Date;
  city: string;
  country: string;
  institution: string;
  logo?: string;
  url?: string;
}

interface LanguageInfo {
  name: string;
  proficiency: LanguageProficiency;
  level?: string;
}

interface SkillInfo {
  name: string;
  level: number;
}

interface PersonalInfo {
  name: string;
  surname: string;
  highlights: Array<string>;
  bio: string;
  email: string;
  twitter: string;
  scholar: string;
  github: string;
  linkedin: string;
  cv: string;
  skills: Array<SkillInfo>;
  languages: Array<LanguageInfo>;
  education: Array<DegreeInfo>;
}

const PERSONAL_INFO: PersonalInfo = {
  name: 'Monica',
  surname: 'Valecha',
  highlights: ['Bioinformatician', 'Computational Biologist', 'Cancer Genomics'],
  bio: 'Bioinformatician with more than 10 years of experience in genomics data analysis. Skilled in NGS data analysis for industry and academic research. Passionate about exploring and analyzing genomics data.',
  email: 'linkmonica@gmail.com',
  twitter: 'linkmonica',
  scholar: 'https://scholar.google.com/citations?user=w0NwuBkAAAAJ',
  github: 'https://github.com/linkmonica/',
  linkedin: 'https://www.linkedin.com/in/monicavalecha/',
  cv: '/Monica_Valecha_CV_2023.pdf',
  education: [
    {
      institution: 'University of Pune',
      city: 'Pune',
      country: 'India',
      name: 'M.Sc. Bioinformatics',
      startDate: new Date('01 June 2011'),
      endDate: new Date('01 June 2013'),
      url: 'http://www.unipune.ac.in/',
    },
    {
      institution: 'R.Y.K. Science College',
      city: 'Nashik',
      country: 'India',
      name: 'B.Sc. Biotechnology',
      startDate: new Date('01 June 2008'),
      endDate: new Date('01 June 2013'),
      url: 'http://www.unipune.ac.in/',
    },
  ],
  skills: [
    {
      name: 'NGS Analysis',
      level: 80,
    },
    {
      name: 'Python',
      level: 15,
    },
    {
      name: 'Single Cell',
      level: 60,
    },
    {
      name: 'R',
      level: 70,
    },
    {
      name: 'Variant Calling',
      level: 80,
    },
    {
      name: 'Data Analysis',
      level: 50,
    },
    {
      name: 'SQL',
      level: 18,
    },
    {
      name: 'slurm',
      level: 60,
    },
    {
      name: 'Linux',
      level: 80,
    },
    {
      name: 'mutationsignatures',
      level: 40,
    },
    {
      name: 'Cancer genomics',
      level: 70,
    },
    {
      name: 'Cloud computing',
      level: 30,
    },
    {
      name: 'DNA-Seq',
      level: 70,
    },
  ],
  languages: [
    {
      name: 'English',
      proficiency: LanguageProficiency.Professional,
    },
    {
      name: 'Hindi',
      proficiency: LanguageProficiency.Native,
    },
    {
      name: 'Spanish',
      proficiency: LanguageProficiency.Limited,
      level: 'A2',
    },
    {
      name: 'Marathi',
      proficiency: LanguageProficiency.Elementary,
    },
    {
      name: 'Sindhi',
      proficiency: LanguageProficiency.Elementary,
    },
  ],
};

export type { PersonalInfo, DegreeInfo, LanguageInfo, LanguageProficiency, SkillInfo };
export { PERSONAL_INFO };
