import { makeStyles } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { SocialLinksStyles } from './SocialLinks.types';

const useSocialLinksStyles = makeStyles<keyof SocialLinksStyles>({
  root: {
    ...shorthands.gap('8px'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    width: '100%',
    textAlign: 'center',
    paddingBottom: '16px',
  },
  list: {
    ...shorthands.gap('16px'),
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: '1fr',
    alignItems: 'start',
    justifyContent: 'center',
    maxWidth: MonicaValechaTokens.maxContentWidth,

    '@media all and (min-width: 300px) and (max-width: 599px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
});

export { useSocialLinksStyles };
