import {
  webLightTheme,
  type Theme,
  themeToTokensObject,
} from '@fluentui/react-components';

type MonicaValechaTokensTypes = {
  navBarColor: string;
  navBarSecondaryColor: string;
  navBarBGColor: string;
  navBarHeight: string;
  blueHighlight: string;
  backToTopBtnBGColor: string;

  // General
  textColor: string;

  // Error
  errorBGColor: string;
  errorColor: string;

  // Hero
  heroBGColor: string;
  heroColor: string;

  maxContentWidth: string;

  // JobCard
  jobCardBackground: string;
};

const monicaValechaLightTheme: Theme & MonicaValechaTokensTypes = {
  ...webLightTheme,
  navBarColor: '#1d1d1d',
  navBarSecondaryColor: '#1f1d1c',
  navBarBGColor: '#A2AC91',
  navBarHeight: '66px',
  blueHighlight: '#1f1d1c',
  backToTopBtnBGColor: '#C99DB9',

  // General
  textColor: '#000',

  // Error
  errorBGColor: '#e93e60',
  errorColor: '#FFF',

  // Hero
  heroBGColor: '#A2AC91',
  heroColor: '#1d1d1d',

  maxContentWidth: '1200px',

  // Job Card
  jobCardBackground: '#FFF',
};

const MonicaValechaTokens = themeToTokensObject(monicaValechaLightTheme);

export { monicaValechaLightTheme, MonicaValechaTokens };
