import { type FunctionComponent } from 'react';
import { useExperienceCardStyles } from './ExperienceCard.styles';
import type { ExperienceCardProps } from './ExperienceCard.types';
import { Text } from '@fluentui/react-components';
import { JobCard } from '../JobCard';

const ExperienceCard: FunctionComponent<ExperienceCardProps> = (props) => {
  const classes = useExperienceCardStyles();

  return (
    <article className={classes.root}>
      <Text as={'h2'} className={classes.title} id={'experience'}>
        {`Experience`}
      </Text>
      <div className={classes.list}>
        {props.experience.map((job) => {
          return <JobCard key={job.company} job={job} compact={props.compact} />;
        })}
      </div>
    </article>
  );
};

export { ExperienceCard };
