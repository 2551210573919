import { useLatestRef } from '../../hooks';
import { useEffect, useMemo, useState, type FunctionComponent } from 'react';
import type { BackToTopBtnProps } from './BackToTopBtn.types';
import { useBackToTopBtnStyles } from './BackToTopBtn.styles';
import { Link } from '@fluentui/react-components';
import { ChevronFilledSvg } from '../../assets/img';

// Write the show state value to a ref so we can use it as a check to prevent
// ...re-renders on every scroll event
const BackToTop: FunctionComponent<BackToTopBtnProps> = () => {
  const classes = useBackToTopBtnStyles();

  const [show, setShow] = useState<boolean>(false);
  const showRef = useLatestRef<boolean>(show);

  useEffect(() => {
    const onScroll = () => {
      if (
        (!showRef.current && window.pageYOffset > 100) ||
        (showRef.current && window.pageYOffset === 0)
      ) {
        setShow((prevShow) => !prevShow);
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [showRef]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const opacity = useMemo(() => (show ? '1' : '0'), [show]);
  const bottom = useMemo(() => (show ? '1.25rem' : '-3.5rem'), [show]);

  return (
    <Link
      className={classes.root}
      role="button"
      aria-label="Back to top"
      onClick={scrollToTop}
      style={{
        opacity: opacity,
        bottom: bottom,
      }}>
      <ChevronFilledSvg className={classes.icon} />
    </Link>
  );
};

export { BackToTop };
