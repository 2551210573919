import { makeStyles } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { HeroStyles } from './Hero.types';

const useHeroStyles = makeStyles<keyof HeroStyles>({
  root: {
    ...shorthands.gap('32px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: MonicaValechaTokens.heroBGColor,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    width: '100%',
    height: '100%',
    minHeight: '300px',
  },
  content: {
    ...shorthands.padding('32px'),
    ...shorthands.gap('8px'),
    ...shorthands.borderRadius('8px'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    maxWidth: MonicaValechaTokens.maxContentWidth,
  },
  avatar: {
    // ...shorthands.borderRadius('50%'),
    ...shorthands.overflow('hidden'),
    width: '200px',
    height: '200px',
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',
    color: MonicaValechaTokens.heroColor,

    '@media all and (max-width: 599px)': {
      fontSize: '20px',
    },
  },
  tagline: {
    ...shorthands.margin('0'),
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '400',
    color: MonicaValechaTokens.heroColor,
    textAlign: 'center',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  description: {
    ...shorthands.margin('0'),
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '200',
    color: MonicaValechaTokens.heroColor,
    textAlign: 'center',

    '@media all and (max-width: 599px)': {
      fontSize: '16px',
    },
  },
});

export { useHeroStyles };
