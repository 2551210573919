import type { FunctionComponent } from 'react';
import { useFooterStyles } from './Footer.styles';
import type { FooterProps } from './Footer.types';
import { mergeClasses } from '@griffel/react';

const Footer: FunctionComponent<FooterProps> = () => {
  const classes = useFooterStyles();

  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <section className={classes.column}></section>
        <section className={classes.column}></section>
      </div>
      <br />
      <div className={mergeClasses(classes.content, classes.copyright)}>
        {`Copyright © ${new Date().getFullYear()} Monica Valecha`}
      </div>
    </footer>
  );
};

export { Footer };
