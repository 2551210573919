import { type FunctionComponent } from 'react';
import { useHeroStyles } from './Hero.styles';
import type { HeroProps } from './Hero.types';
import { Image, Text } from '@fluentui/react-components';
import { avatarOutUrl, avatarOutWebPUrl } from '../../assets/img';
import { useInView } from 'react-intersection-observer';
import { PERSONAL_INFO } from '../../config/about';

const Hero: FunctionComponent<HeroProps> = (props) => {
  const classes = useHeroStyles();

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView?: boolean) => {
      if (props.onVisibilityChange) {
        props.onVisibilityChange(Boolean(inView));
      }
    },
  });

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <picture>
          <source srcSet={avatarOutWebPUrl} type="image/webp" />
          <source srcSet={avatarOutUrl} type="image/png" />
          <Image
            ref={ref}
            alt={`${PERSONAL_INFO.name} ${PERSONAL_INFO.surname}`}
            src={avatarOutUrl}
            className={classes.avatar}
          />
        </picture>
        <Text as={'h1'} className={classes.title}>
          {`${PERSONAL_INFO.name} ${PERSONAL_INFO.surname}`}
        </Text>
        <Text as={'h2'} className={classes.tagline}>
          {PERSONAL_INFO.highlights.join(' | ')}
        </Text>
        <Text as={'p'} className={classes.description}>
          {PERSONAL_INFO.bio}
        </Text>
      </div>
    </section>
  );
};

export { Hero };
