import { useMemo, type FunctionComponent } from 'react';
import { useDegreeCardStyles } from './DegreeCard.styles';
import type { DegreeCardProps } from './DegreeCard.types';
import { Text, Link } from '@fluentui/react-components';
import { HatGraduationSvg } from '../../assets/img/HatGraduation';

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
};

const DegreeCard: FunctionComponent<DegreeCardProps> = (props) => {
  const classes = useDegreeCardStyles();

  const degree = useMemo(() => props.degree, [props.degree]);

  const displayTime = useMemo(() => {
    const start = `${degree.startDate.toLocaleDateString('en-US', DATE_FORMAT)}`;
    let end = `Current`;
    if (degree.endDate) {
      end = `${degree.endDate.toLocaleDateString('en-US', DATE_FORMAT)}`;
    }

    return `${start} - ${end}`;
  }, [degree.startDate, degree.endDate]);

  return (
    <article key={degree.institution} className={classes.root}>
      <HatGraduationSvg className={classes.logo} />
      <Text as={'h3'} className={classes.degree}>
        {degree.name}
      </Text>
      {degree.url ? (
        <Link
          href={degree.url}
          aria-label={degree.institution}
          title={degree.institution}>
          <Text as={'h4'} className={classes.institution}>
            {degree.institution}
          </Text>
        </Link>
      ) : (
        <Text as={'h4'} className={classes.institution}>
          {degree.institution}
        </Text>
      )}
      <Text as={'p'} className={classes.date}>
        {displayTime}
      </Text>
      <Text as={'p'} className={classes.location}>
        {`${degree.city}, ${degree.country}`}
      </Text>
    </article>
  );
};

export { DegreeCard };
