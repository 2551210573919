import { makeStyles, shorthands } from '@fluentui/react-components';
import type { HomeStyles } from './Home.types';

const useHomeStyles = makeStyles<keyof HomeStyles>({
  root: {
    boxSizing: 'border-box',
    minHeight: '2000px',
    width: '100%',
    height: '100%',
  },
  content: {
    ...shorthands.padding('16px'),
    ...shorthands.gap('32px'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    height: '100%',
  },
  subtitle: {
    ...shorthands.margin('0'),
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  text: {
    ...shorthands.margin('0'),
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '400',
  },
  link: {},
  clinic: {
    ...shorthands.gap('16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  clinicPicture: {
    ...shorthands.borderRadius('8px'),
    width: '300px',
  },
  registeredBodies: {
    ...shorthands.gap('16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export { useHomeStyles };
