import type { ComponentType } from 'react';
import { Experience, Home, Publications } from '../containers';

type MetaInfoProps = Partial<
  Readonly<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: any[];
    lang: string;
    title: string;
    defer: boolean;
    locale: string;
    description: string;
  }>
>;

type Route = Readonly<{
  id: string;
  path: string;
  name: string;
  hide?: boolean;
  noLink?: boolean;
  metaInfo: MetaInfoProps;
  children?: Array<Route>;
  Component: ComponentType;
}>;

const routes: Route[] = [
  {
    id: 'HOME',
    path: '/',
    name: 'About',
    Component: Home,
    hide: false,
    metaInfo: {
      title: 'Monica Valecha - Bioinformatician - Cancer Genomics',
      description:
        'Bioinformatician with more than 10 years of experience in genomics data analysis. Skilled in NGS data analysis for industry and academic research.',
    },
  },
  {
    id: 'EXPERIENCE',
    path: '/experience',
    name: 'Experience',
    Component: Experience,
    hide: false,
    metaInfo: {
      title: 'Experience - Monica Valecha - Bioinformatician',
      description:
        'Discover how I applied my skills and expertise for industry and academic research. Learn how I can help you with your genomics data needs.',
    },
  },
  {
    id: 'PUBLICATIONS',
    path: '/publications',
    name: 'Publications',
    Component: Publications,
    hide: false,
    metaInfo: {
      title: 'Publications - Monica Valecha - Bioinformatician',
      description: `Browse through my publications in peer-reviewed journals and conferences and find out how I have contributed to the advancement of genomics research.`,
    },
  },
];

/**
 * Finds a route from a List of routes with children
 *
 * @param list The list of Routes
 * @param id The Child ID
 * @param parents The List of parents to the child
 * @returns The Route
 */
const findChild = (
  list: Array<Route>,
  id: string,
  parents?: Array<string>
): Route | undefined => {
  if (parents && parents.length > 0) {
    return findChild(list, id, parents.slice(1));
  } else {
    return list.find((r) => r.id === id);
  }
};

/**
 * Gets the metadata for the Route
 *
 * @param id The ID of the Route
 * @param parents The parents of the route
 * @returns The Route Metadata
 */
const getRouteMetaInfo = (id: string, parents?: Array<string>): MetaInfoProps => {
  const route = findChild(routes, id, parents);
  return route?.metaInfo ?? {};
};

const isLocationValidRoute = (pathname: string): boolean => {
  // console.warn('TODO: Review this function to support child routes.');
  return routes.some((r) => r.path === pathname);
};

export { routes, getRouteMetaInfo, isLocationValidRoute };

export type { MetaInfoProps, Route };
