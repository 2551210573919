import { type FunctionComponent } from 'react';
import { useSocialLinksStyles } from './SocialLinks.styles';
import type { SocialLinksProps } from './SocialLinks.types';
import { Text } from '@fluentui/react-components';
import { SocialLink } from '../SocialLink/SocialLink';
import { SocialLinkType } from '../SocialLink/SocialLink.types';

const SocialLinks: FunctionComponent<SocialLinksProps> = (props) => {
  const classes = useSocialLinksStyles();

  if (props.compact) {
    return (
      <article className={classes.root}>
        <div className={classes.list}>
          <SocialLink type={SocialLinkType.Email} value={props.email} />
          <SocialLink type={SocialLinkType.Linkedin} value={props.linkedin} />
          {/* <SocialLink type={SocialLinkType.CV} value={props.cv} /> */}
          <SocialLink type={SocialLinkType.Scholar} value={props.scholar} />
          <SocialLink type={SocialLinkType.Twitter} value={props.twitter} />
          <SocialLink type={SocialLinkType.Github} value={props.github} />
        </div>
      </article>
    );
  } else {
    return (
      <article className={classes.root}>
        <Text as={'h2'} className={classes.title} id={'social'}>
          {`Links`}
        </Text>
        <div className={classes.list}>
          <SocialLink type={SocialLinkType.Email} value={props.email} />
          <SocialLink type={SocialLinkType.Linkedin} value={props.linkedin} />
          {/* <SocialLink type={SocialLinkType.CV} value={props.cv} /> */}
          <SocialLink type={SocialLinkType.Scholar} value={props.scholar} />
          <SocialLink type={SocialLinkType.Twitter} value={props.twitter} />
          <SocialLink type={SocialLinkType.Github} value={props.github} />
        </div>
      </article>
    );
  }
};

export { SocialLinks };
