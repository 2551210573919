import { useMemo, type FunctionComponent } from 'react';
import { useSocialLinkStyles } from './SocialLink.styles';
import { SocialLinkType, type SocialLinkProps } from './SocialLink.types';
import { Link } from '@fluentui/react-components';
import { EmailFilledSvg } from '../../assets/img/EmailFilled';
import { GithubFilledSvg } from '../../assets/img/GithubFilled';
import { LinkedinFilledSvg } from '../../assets/img/Linkedin';
import { ScholarFilledSvg } from '../../assets/img/Scholar';
import { TwitterSvg } from '../../assets/img/Twitter';
import { ResumeSvg } from '../../assets/img/Resume';

const SocialLink: FunctionComponent<SocialLinkProps> = (props) => {
  const classes = useSocialLinkStyles();

  const { title, url, icon } = useMemo(() => {
    switch (props.type) {
      case SocialLinkType.CV:
        return {
          title: 'CV',
          url: props.value,
          icon: <ResumeSvg className={classes.logo} role={'presentation'} />,
        };
      case SocialLinkType.Email:
        return {
          title: 'Email',
          url: `mailto:${props.value ?? ''}`,
          icon: <EmailFilledSvg className={classes.logo} role={'presentation'} />,
        };
      case SocialLinkType.Github:
        return {
          title: 'GitHub',
          url: props.value,
          icon: <GithubFilledSvg className={classes.logo} role={'presentation'} />,
        };
      case SocialLinkType.Linkedin:
        return {
          title: 'Linkedin',
          url: props.value,
          icon: <LinkedinFilledSvg className={classes.logo} role={'presentation'} />,
        };
      case SocialLinkType.Scholar:
        return {
          title: 'Scholar',
          url: props.value,
          icon: <ScholarFilledSvg className={classes.logo} role={'presentation'} />,
        };
      case SocialLinkType.Twitter:
        return {
          title: 'Twitter',
          url: `https://twitter.com/${props.value ?? ''}`,
          icon: <TwitterSvg className={classes.logo} role={'presentation'} />,
        };
      default:
        return {
          title: '',
          url: '',
          icon: '',
        };
    }
  }, [props.type, props.value]);

  if (url) {
    return (
      <article key={title} className={classes.root}>
        <Link
          type={'button'}
          href={url}
          target={'_blank'}
          aria-label={title}
          title={title}>
          {icon}
          {/* <Text as={'h3'} className={classes.title}>
            {title}
          </Text> */}
        </Link>
      </article>
    );
  } else {
    return <></>;
  }
};

export { SocialLink };
