import { useMemo, type FunctionComponent, type ReactNode } from 'react';
import { usePublicationCardStyles } from './PublicationCard.styles';
import type { PublicationCardProps } from './PublicationCard.types';
import { Text, Link, mergeClasses } from '@fluentui/react-components';

const NAMES_OF_MONICA: Array<string> = [
  'Monica Valecha',
  'Monica Vishnu Valecha',
  'M Valecha',
  'Valecha M',
  'Valecha, M.V',
];

const PublicationCard: FunctionComponent<PublicationCardProps> = (props) => {
  const classes = usePublicationCardStyles();
  const publication = useMemo(() => props.publication, [props.publication]);

  const authors = useMemo(() => {
    const authorList = new Array<ReactNode>(publication.authors.length);

    for (let i = 0; i < publication.authors.length; i += 1) {
      const author = publication.authors[i];
      if (NAMES_OF_MONICA.includes(author)) {
        authorList.push(
          <strong className={classes.me} key={author}>
            {author}
          </strong>
        );
      } else {
        authorList.push(
          <span className={classes.others} key={author}>
            {author}
          </span>
        );
      }

      if (i < publication.authors.length - 1) {
        authorList.push(
          <span key={`${author}-${i}`} className={classes.separator}>
            {', '}
          </span>
        );
      }
    }

    return <>{authorList}</>;
  }, publication.authors);

  return (
    <article
      key={publication.title}
      className={mergeClasses(classes.root, classes.smallGap)}>
      {publication.url ? (
        <Link
          href={publication.url}
          aria-label={publication.title}
          title={publication.title}>
          <Text as={'h3'} className={classes.title}>
            {publication.title}
          </Text>
        </Link>
      ) : (
        <Text as={'h3'} className={classes.title}>
          {publication.title}
        </Text>
      )}
      <Text as={'h4'} className={classes.journal}>
        {publication.journal}
      </Text>
      <Text as={'p'} className={classes.authors}>
        {authors}
      </Text>{' '}
      <Text as={'p'} className={classes.details}>
        {publication.details.join(', ')}
      </Text>
    </article>
  );
};

export { PublicationCard };
