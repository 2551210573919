import { makeStyles } from '@fluentui/react-components';
import { MonicaValechaTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { SkillsCloudStyles } from './SkillsCloud.types';

const useSkillsCloudStyles = makeStyles<keyof SkillsCloudStyles>({
  root: {
    ...shorthands.gap('8px'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    maxWidth: MonicaValechaTokens.maxContentWidth,
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    width: '100%',
    textAlign: 'center',
  },
  cloud: {
    // backgroundColor: MonicaValechaTokens.jobCardBackground,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '> div': {
      maxWidth: '600px',
    },
  },
  skill: {
    ...shorthands.padding('8px', '16px'),
    display: 'inline-block',
  },
});

export { useSkillsCloudStyles };
